<template>
  
</template>

<script>
  const section = document.querySelector(".book-showcase");
  const book = document.querySelector(".book");

  let prev = 0;
  let calc = 0;
  const sensitivity = 2;

  export default {
    setup() {
      

    }
  }
</script>

<style scoped>
  
</style>
