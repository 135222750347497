<template>
  <section id="features" class="features section-bg">
    <div class="container" data-aos="fade-up">
      <div class="section-header">
        <h2>Our Product</h2>
      </div>
      <ul class="nav nav-tabs row g-2 d-flex">
        <li class="nav-item col-lg-3 col-md-3 col-sm-12">
          <a
            class="nav-link active show"
            data-bs-toggle="tab"
            data-bs-target="#tab-1"
          >
            <h4>Distribution Transformer</h4>
          </a>
        </li>
        <!-- End tab nav item -->

        <li class="nav-item col-lg-3 col-md-3 col-sm-12">
          <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
            <h4>Power Transformer</h4>
          </a>
          <!-- End tab nav item -->
        </li>

        <li class="nav-item col-lg-3 col-md-3 col-sm-12">
          <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">
            <h4>Panels</h4>
          </a>
        </li>
        <!-- End tab nav item -->

        <li class="nav-item col-lg-3 col-md-3 col-sm-12">
          <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-4">
            <h4>Package Type Transformer</h4>
          </a>
        </li>
        <!-- End tab nav item -->
      </ul>

      <div class="tab-content">
        <div class="tab-pane active show" id="tab-1">
          <div class="row mt-5">
            <div
              class="dt col-lg-6 col-sm-12 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <h3>Oil Immersed Distribution Transformer </h3>
              <p style="text-align: justify;" class="">
                Hitachi Soe Electric & Machinery Co., Ltd (HISEM) is manufacturing Oil Immersed Type Distribution Transformer with two categories, Hermetically Sealed Type Transformer & Conservator Tank Type Transformer. Oil Immersed Type Distribution Transformers are designed and manufactured in accordance with the national and international standards (IEC, IEEE, etc.). Those types of transformers are mostly used in electrical power distribution systems. The heat generated during operation is maintained and cooled at the normal temperature level with transformer oil. HISEM Oil Immersed Distribution Transformers are available up to 3,000 kVA and Voltage level up to 33kV. 
              </p>
              <div class="ps-3 mb-2">
                <h6 class="fw-bold">1.1 Hermetically Sealed Type Oil Immersed Distribution Transformer </h6>
                <p style="text-align: justify;" class="ps-4 ">HISEM produces in a range of 5kVA to 800kVA, up to 33kV voltage level, three or single phase, natural cooled (ONAN or KNAN) with no load tap changer or automatic on load tap changer transformer. Hermetically sealed transformer does not allow the cooling oil to come into contact with the atmosphere. As a result, it does not require oil change in certain periods as in transformer with conservator tank. </p>
              </div>
              <div class="ps-3 mb-2">
                <h6 class="fw-bold">1.2 Conservator Tank Type Oil Immersed Distribution Transformer </h6>
                <p style="text-align: justify;" class="ps-4 ">HISEM produces in a range of 1,000kVA to 3,000kVA, up to 33kV voltage level, three or single phase, natural cooled (ONAN or KNAN) with no load tap changer or automatic on load tap changer transformer. The size of the conservator is so designed as a result of calculations to store the oil of which volume increases with temperature. The oil pressure is changed due to thermal effects, and the air exchange is sure to take moisture of the air through the silica gel into transformer tank. For this reason, it is necessary to do undergo periodic maintenance such as changing the silica gel and measuring the dielectric breakdown voltage by sampling the oil.  </p>
              </div>
            </div>
            <div
              class="img-container col-lg-6 col-sm-12 order-1 order-lg-2 text-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <img
                src="@/assets/images/products/100-1.jpg"
                alt=""
                class="img-fluid product-img"
              />
            </div>
          </div>
        </div>
        <!-- End tab content item -->

        <div class="tab-pane" id="tab-2">
          <div class="row">
            <div
              class="dt col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center"
            >
              <h3>Oil Immersed Power Transformer </h3>
              <p style="text-align: justify;" class="fst-italic">
                HISEM produces in a range of 3,000kVA to 30,000kVA, up to 66kV voltage level, three phase, natural cooled (ONAN) or forced cooled (ONAF) with no load tap changer or automatic on load tap changer power transformer. Oil Immersed Type Power Transformers are designed and manufactured in accordance with the national and international standards (IEC, IEEE, etc.). Those types of transformers are mostly used in electricity generation, electricity transmission & distribution substation, industrial plant, power generation center and electrical internal needs, etc. 
              </p>
            </div>
            <div class="img-container col-lg-6 order-1 order-lg-2 text-center">
              <img
                src="@/assets/images/products/10000.jpg"
                alt=""
                class="img-fluid product-img"
              />
            </div>
          </div>
        </div>
        <!-- End tab content item -->

        <div class="tab-pane" id="tab-3">
          <div class="row">
            <div
              class="dt col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center"
            >
              <h3>Panels</h3>
              <p style="text-align: justify;" class="">
                Hitachi Soe Electric & Machinery Co., Ltd (HISEM) have manufactured panel products with two categories, low voltage (LV) Panel & medium voltage (MV) panel. Panels are designed and manufactured in accordance with the national and international standards (IEC, IEEE, etc.). Those types of panels are mostly used in electrical power distribution systems.  

                Low Voltage (LV) Panel and medium voltage (MV) panel are normally designed in compact size applied in indoor and outdoor power distribution system. Panel products are manufactured under the strict supervision of the electrical inspection team following strict procedures. Good quality products manufactured in accordance with international standards so that customers can benefit from them.  
              </p>
              <div class="ps-3 mb-2">
                <h6 class="fw-bold">1.1 Low Voltage (LV) Panel  </h6>
                <p style="text-align: justify;" class="ps-4">LV panels are produced according to customer request in addition to HISEM’s standard design. In HISEM, LV panels can be used indoor or outdoor, as well as floor stand, wall mounted and pole mounted type.  </p>
              </div>
              <div class="ps-3 mb-2">
                <h6 class="fw-bold">1.2 Medium Voltage (MV) Panel   </h6>
                <p style="text-align: justify;" class="ps-4">As medium voltage (MV) panel, HISEM produces 11kV hybrid VCB switchgear panel and LA, DS panel with drop out fuse (or) enclosed cutout fuse. LA, DS panel with drop out fuse or enclosed cutout fuse includes lightning arrester, disconnecting switch and drop out fuse or enclosed cutout fuse. Switchgear panels are fully factory assembly which include hybrid VCB, overcurrent & earth fault relay, CT, PT and lightning arrester. For the first time in Myanmar, Hybrid VCB is produced with Japanese technology. 11kV hybrid VCB is available in both 630A and 1250A rated current.  </p>
              </div>
            </div>
            <div class="img-container col-lg-6 order-1 order-lg-2 text-center">
              <img
                src="@/assets/images/products/panels-3.jpg"
                alt=""
                class="img-fluid product-img"
              />
            </div>
          </div>
        </div>
        <!-- End tab content item -->

        <div class="tab-pane" id="tab-4">
          <div class="row">
            <div
              class="dt col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center"
            >
              <h3>Unit Package Substation </h3>
              <p style="text-align: justify;" class="fst-italic mb-1">
                The unit package substation is normally designed in compact size applied in indoor and outdoor power distribution system. HISEM’s unit package substations are prefabricated with fully factory assembly which include an integrated distribution transformer, MV and LV switchgear, power factor improvement equipment, backup power unit for OLTC and other related accessories such as lighting circuits, cooling facilities etc. The inside distribution transformer of both types of oil immersed and dry type are available in capacity ranging from 50kVA to 2000kVA with voltage level up to 33kV. The advantages of HISEM unit package substation are as follows: 
              </p>
              <ul class="ps-4" style="list-style: circle;">
                <li>Suitable for indoor and outdoor applications</li>
                <li>Safety for operating personnel </li>
                <li>Compact design and fully factory assembly </li>
                <li>Easy for transportation and installation </li>
                <li>Minimal procuring, land space and installation time </li>
                <li>Proof mechanical impact </li>
                <li>Adequate access for carrying out routine maintenance work </li>
                <li>Enclosure and all equipment are tested to be in-line with IEC recommendations  </li>
              </ul>
            </div>
            <div class="img-container col-lg-6 order-1 order-lg-2 text-center">
              <img
                src="@/assets/images/products/package.jpg"
                alt=""
                class="img-fluid product-img"
              />
            </div>
          </div>
        </div>
        <!-- End tab content item -->
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
#features {
  width: 100%;
  margin-bottom: 80px;
}
.product-img {
  width: 70%;
  height: 50vh;
}

.features .nav-tabs {
  border: 0;
}

.features .nav-link {
  padding: 15px 0;
  transition: 0.3s;
  color: #000;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  border: 0;
  border-bottom: 4px solid #e2e4e6;
}

.features .nav-link:hover {
  color: #c02;
}

.features .nav-link h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.features .nav-link.active {
  background-color: transparent;
  border-color: #c02;
}
.features .tab-content {
  margin-top: 30px;
}
.features .tab-pane h3 {
  font-weight: 700;
  font-size: 32px;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.features .tab-pane h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 3px;
  background: #c02;
  left: 0;
  bottom: 0;
}
.features .tab-pane ul {
  list-style: none;
  padding: 0;
}
.features .tab-pane ul li {
  padding-top: 10px;
}
.features .tab-pane p:last-child {
  margin-bottom: 0;
}
.section-header {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-header h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #000;
  position: relative;
  z-index: 2;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}
.section-header h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #c02;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.section-header p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}
.btn-danger {
  background-color: #c02;
}

@media (min-width: 450px) and (max-width: 850px) {
  .features .nav-link h4 {
    font-size: 14px !important;
  }
  .section-header h2 {
    font-size: 29px;
  }
  .features .tab-pane h3 {
    font-size: 29px;
  }
  .tab-pane .img-container {
    height: 490px !important;
  }
  .tab-pane .img-container img {
    height: 480px !important;
  }
  .tab-pane#tab-2 img {
    width: 320px !important;
    height: 370px !important;
  }
  .tab-pane h3 {
    font-size: 1.4rem !important;
  }
  .tab-pane p,
  .tab-pane ul {
    font-size: 0.9rem !important;
    text-align: justify;
  }
  .tab-pane a button {
    display: inline-block !important;
    font-size: 0.8rem;
  }
}

@media (max-width: 640px) {
  .section-header h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }
  .section-header span {
    font-size: 38px;
  }
}

@media (max-width: 450px) {
  .features {
    width: 80%;
  }
  .features .nav-tabs {
    padding: 0 32px !important;
  }
  .tap-content {
    padding: 0 32px !important;
  }
  .section-header h2 {
    font-size: 20px;
  }
  .features .tab-pane h3 {
    font-size: 20px;
  }
  .careers_btn {
    padding: 5px 20px !important;
  }
  .btn-group-lg > .btn,
  .btn-lg {
    font-size: 1rem;
  }
  .tab-content {
    margin-top: 2rem;
  }
  .tab-pane .img-container {
    height: 280px;
  }
  .tab-pane .img-container img {
    width: 180px;
    height: 230px;
  }
  .features h2 {
    font-size: 1.1rem;
    padding-top: 0px !important;
  }
  .features h4 {
    font-size: 1rem;
  }
  .tab-pane h3 {
    font-size: 1.1rem !important;
  }
  .tab-pane p,
  .tab-pane ul {
    font-size: 0.9rem !important;
    text-align: justify;
  }
  .tab-pane a button {
    font-size: 0.7rem;
  }
  .tab-pane .dt {
    padding: 0 20px;
    margin: 0 auto;
  }
}
</style>
